import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from '@/modules/authorization/store';
import commonStore from '@/shared/store';
import accountStatement from '@/modules/bets/store'

const mutations = {
  resetState(state) {
    state.auth.user = null;
    state.auth.token = null;
    state.auth = {
      user: null,
      chips: null,
      token: null,
      eventManage: null,
      eventDetailHideSections: []
    };
  }
}
const actions = {
  resetAllState({ commit }) {
    commit('resetState')
  }
}

export default createStore({
  state: {},
  mutations,
  actions,
  modules: {auth,commonStore,accountStatement },
  plugins: [createPersistedState({
    key:'sd:site'
  })]
});