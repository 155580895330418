const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const LiveCasino = () => import(/* webpackChunkName: "games" */ './views/LiveCasino.vue');
const SlotGames = () => import(/* webpackChunkName: "slot-games" */ './views/SlotGames.vue');
const IndianCardGames = () =>  import(/* webpackChunkName: "indian-card-games" */ './views/IndianCardGames.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'games/:type?',
            name: 'Games',
            component: LiveCasino,
            props: true
        },
        {
            path: 'multiplayer-games',
            name: 'multiplayer-games',
            component: SlotGames
        },
        // {
        //     path: 'home-section',
        //     name: 'home-section',
        //     component: LiveCasino
        // },
        // {
        //     path: 'evolution',
        //     name: 'evolution',
        //     component: LiveCasino
        // },
        // {
        //     path: 'royal-gaming',
        //     name: 'royal-gaming',
        //     component: LiveCasino
        // },
        {
            path: 'slot-games',
            name: 'slot-games',
            component: SlotGames
        },
        {
            path: 'fishing-games',
            name: 'fishing-games',
            component: SlotGames
        },
        {
            path: 'indian-card-games',
            name: 'indian-card-games',
            component: IndianCardGames
        }

    ],
}

export default GamesRoutes;