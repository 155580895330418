const MyMarketModule = () => import(/* webpackChunkName: "my-market-module" */ './views/Module.vue');
const MultiMarket = () => import(/* webpackChunkName: "my-market" */ './views/MultiMarket.vue');

const MyMarketRoutes = {
    path: '/',
    component: MyMarketModule,
    children: [
        {
            path: 'favourites',
            name: 'MultiMarket',
            component: MultiMarket
        }
    ],
}

export default MyMarketRoutes;