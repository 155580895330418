import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const Login = () => import("@/modules/authorization/components/Login.vue");
const SignUp = () => import("@/modules/authorization/components/SignUp.vue");
const ForgetPassword = () => import("@/modules/authorization/components/ForgetPassword.vue");
const MaintenanceComponent = () => import("@/shared/components/maintenance/Maintainence.vue");
const PageNotFound = () => import("@/shared/components/no-data/PageNotFound.vue");
// import FavouriteRoutes from "@/modules/favourites";
import MatkaRoutes from "@/modules/matka";
import BetsRoutes from "@/modules/bets";
import StatementRoutes from "@/modules/statement";
import MyMarketRoutes from "@/modules/my-market";
import ReferEarnRoutes from "@/modules/refer-earn";
import BonusRoutes from "@/modules/bonus";
import RaceRoutes from "@/modules/race";
import GamesRoutes from "@/modules/games";
import WalletRoutes from "@/modules/wallet";
import SportsRoutes from "@/modules/sports";
import PoliciesRoutes from "@/modules/policies";
import SettingRoutes from "@/modules/setting";
import CricketFightRoutes from "@/modules/cricket-fight";
import VirtualSportRoutes from "@/modules/virtual-sport";
import NotificationRoutes from '@/modules/notification';
import AffiliateRoutes from '@/modules/affiliate';
const routes = [
    {
      path: "/",
      name: "Home",
      component: Home,
      props: { key: "home" }
    },
    {
      path: "/poolzone",
      name: "Poolzone",
      component: Home,
      props: { key: "poolzone" }
    },
     {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "register",
    component: SignUp
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: ForgetPassword
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceComponent
  },

    // FavouriteRoutes,
    MatkaRoutes,
    BetsRoutes,
    StatementRoutes,
    MyMarketRoutes,
    ReferEarnRoutes,
    BonusRoutes,
    RaceRoutes,
    GamesRoutes,
    WalletRoutes,
    SportsRoutes,
    PoliciesRoutes,
    SettingRoutes,
    CricketFightRoutes,
    VirtualSportRoutes,
    NotificationRoutes,
    AffiliateRoutes,
    {
      path: "/:pathMatch(.*)*",
      name: "PageNotFound",
      component: PageNotFound
    }
  ];
  
  
  export default createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 };
    },
  });