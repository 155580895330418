const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    isClickBetOn: false,
    favouriteGames:null,
    globalLoader:false,
    // depositAmount: 0,
    notification:0,
    languagesData:null,
    balExpDisplay:[
        'balance',
        'exposure'
    ],
    languageSelected:null,
    menuShow:true,
    sports_list:null,
    siteVersion:null,
    underMaintenance:null,
    successMsg: null,
    errorMsg: null,
    loggedUser:null,
    guestUserName:null,
    bonusData: null,
    userBonus: null,
    logInData: null

};
const getters = {
    siteSettings: state => state?.siteSettings,
    gamesData: state => state?.gamesData,
    bonusData: state => state?.bonusData,
    languagesData: state => state?.siteSettings?.languagesList,
    languageSelected: state => state?.languageSelected,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    isClickBetOn: state => state?.isClickBetOn,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    // depositAmount:state => state?.depositAmount,
    getNotification: state => state?.notification,
    getBalExpDisplay: state => state?.balExpDisplay,
    getRecommendedMatch: state => state?.recommendedMatch,
    menuShow: state => state?.menuShow,
    sports_list: state => state?.sports_list,
    siteVersion: state => state?.siteVersion,
    underMaintenance: state => state?.underMaintenance,
    successMsg: state => state?.successMsg,
    errorMsg: state => state?.errorMsg,
    loggedUser:state => state?.loggedUser,
    guestUserName:state => state?.guestUserName,
    getSportImage : state =>(sportId) => {
        const imageMap = {
            4: require("@/assets/images/inplay-cricket-icon.webp"),
            1: require("@/assets/images/inplay-football-icon.webp"),
            2: require("@/assets/images/inplay-tennis-icon.webp"),
            27454571: require("@/assets/images/esports.webp"),
            2152880: require("@/assets/images/gaelic-games.webp"),
            998917: require("@/assets/images/volleyball.webp"),
            7524: require("@/assets/images/ice-hockey.webp"),
            7522: require("@/assets/images/basketball.webp"),
            7511: require("@/assets/images/baseball.webp"),
            6423: require("@/assets/images/american-football.webp"),
            6422: require("@/assets/images/snooker.webp"),
            27454572: require("@/assets/images/kabaddi.webp"),
            27454574: require("@/assets/images/table-tennis.webp"),
            27454581: require("@/assets/images/9ball-pool.svg"),
            468328: require("@/assets/images/handball.webp"),
            61420: require("@/assets/images/australian-rules.webp"),
            26420387: require("@/assets/images/mixed-martial-arts.webp"),
        };
        return imageMap[sportId] || '';
    },
    userBonus: state => state?.userBonus,
    logInData: state => state?.logInData

};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setBonusData({ commit }, bonusData) {
        commit('setBonusData', bonusData);
    },
    setLanguageSelected({ commit }, languageSelected) {
        commit('setLanguageSelected', languageSelected);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setIsClickBetOn({ commit }, isClickBetOn) {
        commit('setIsClickBetOn', isClickBetOn);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    // setDepositAmount({ commit }, depositAmount) {
    //     commit('setDepositAmount', depositAmount);
    // },    
    setNotification({ commit }, notification) {
        commit('setNotification', notification);
    },
    setBalExpDisplay({ commit }, balExpDisplay) {
        commit('setBalExpDisplay', balExpDisplay);
    },
    setRecommendedMatch({ commit }, RecommendedMatch) {
        commit('setRecommendedMatch', RecommendedMatch);
    },
    setMenuShow({ commit }, menuShow) {
        commit('setMenuShow', menuShow);
    },
    setSportsList({ commit }, sports_list) {
        commit('setSportsList', sports_list);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },
    showSuccessModal({ commit }, message) {
        commit('showSuccessModal', message);
        setTimeout(() => {
            commit('showSuccessModal', null);
        }, 2000);
    },
    showErrorModal({ commit }, message) {
        commit('showErrorModal', message);
        setTimeout(() => {
            commit('showErrorModal', null);
        }, 2000);
    },
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    setLoggedUser({ commit }, loggedUser) {
        commit('setLoggedUser', loggedUser);
    },
    setguestUserName({ commit }, guestUserName) {
        commit('setguestUserName', guestUserName);
    },
    setUserBonus({ commit }, userBonus) {
        commit('setUserBonus', userBonus);
    },
    setLogInData({commit}, data) {
        commit('setLogInData', data)
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setBonusData(state, bonusData) {
        state.bonusData = bonusData;;
    },
    setLanguageSelected(state, languageSelected) {
        state.languageSelected = languageSelected;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setIsClickBetOn(state, isClickBetOn) {
        state.isClickBetOn = isClickBetOn;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    // setDepositAmount(state, depositAmount){
    //     state.depositAmount =  depositAmount
    // },    
    setNotification(state) {
        state.notification = state.notification+1;
    },
    setBalExpDisplay(state, balExpDisplay) {
        state.balExpDisplay = balExpDisplay;
    },
    setRecommendedMatch(state, RecommendedMatch) {
        state.recommendedMatch = RecommendedMatch;
    },
    setMenuShow(state, menuShow) {
        state.menuShow = menuShow;
    },
    setSportsList(state, sports_list) {
        state.sports_list = sports_list;
    },
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    showSuccessModal(state, message) {
        state.successMsg = message
    },
    showErrorModal(state, message) {
        state.errorMsg = message
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },
    setLoggedUser(state, loggedUser) {
        state.loggedUser = loggedUser;
    },
    setguestUserName(state, guestUserName) {
        state.guestUserName = guestUserName;
    },
    setUserBonus(state, userBonus) {
        state.userBonus = userBonus
    },
    setLogInData(state, data) {
        state.logInData = data
    }
    
};
export default {
    state,
    getters,
    actions,
    mutations
};