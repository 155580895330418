
export default function handlerErrorFromApis(error) {
    if(!error?.response) {
        return Promise.reject([`Error CORS: Check your NETWORK agian and refresh the page.`]);
    };
    const status = error?.response?.status;
    switch (status) {
        case 422:
            if (error.response.data.details) {
                return Promise.reject(`Error ${status}: ${error?.response?.data?.details?.[Object.keys(error?.response?.data?.details)?.[0]]}`);
            } else {
                return Promise.reject([`Error ${status}: ${error?.response?.data?.message}`]);
            }
        case 401:
            clearDataOnUnauthorised();
            break;
        case 400:
            return Promise.reject([`Error ${status}: ${error?.response?.data?.error}`]);
        case 500:
            return Promise.reject([`Error ${status}: ${error?.response?.data?.message ?? error?.response?.data?.msg}`]);
        default:
            return Promise.reject([`Error ${status}: Refresh the page and try again.`]);
    }
}

function clearDataOnUnauthorised() {
    window.cookies.keys().forEach(cookie => window.cookies.remove(cookie))
    window.store.dispatch('resetAllState');
    window.router.push({name:'Home'});
}