<template>
 <div class="modal fade game-play-modal" id="gamesIframe" tabindex="-1" aria-labelledby="gamesModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-fullscreen" style="max-width: 100%;">
        <div v-if="gameData" class="modal-content">
            <div class="modal-header">
                <div class="game-header-content">
                    <div class="game-bak-close-block">
                        <a ref="close_button" @click="$emit('closeGame')" data-bs-dismiss="modal"><img
                                src="@/assets/images/back-arrow.webp" alt="back-arrow"></a>
                        <strong>{{gameData?.gameName}}</strong>
                    </div>
                    <!-- <div class="close-game-btn">
                        <a data-bs-dismiss="modal"><img loading="lazy" src="@/assets/images/red-close-btn.webp" alt="red-close-btnsz"></a>
                    </div> -->
                </div>
            </div>
            <div class="modal-body">
                <div class="game-iframe-sec">
                    <div v-if="this.gameData" class="iframe-content">
                            <iframe :src="this.gameData.gameUrl" frameborder="0"
                            style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                            height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen title="Tr&amp;eacute;ning TEXT"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name: 'IFrameModal',
    props: ['gameData'],
}
</script>