import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const dateTime = {
    sportItemDate: (date, format = 'D MMM, h:mm A') => {
        return dayjs(date).format(format);
    },
    convertUnixDate: (date, format = 'DD MMM YYYY HH:mm:ss') => {
        return dayjs.unix(date).format(format)
    },
    currentTimestamp: () => {
        return dayjs().unix();
    },
    getcurrentDate: (format = 'YYYY-MM-DD', date = new Date()) => {
        return dayjs(date).format(format);
    },
    // affiliate -----------------------------------------
    getaffcurrentDate: (date, format = 'YYYY-MM-DD') => {
        return dayjs(date).format(format);
    },
    // ---------------------------------------------------
    getDateSaperatedByDays: (days, date = new Date(), format = 'YYYY-MM-DD' ) => {
        return dayjs(date).subtract(days, 'day').format(format);
    },
    convertOnlyTime: (time, format = 'hh:mm A') =>{
        return dayjs(time, "HH:mm").format(format);
    },
    convertUtcDate: (date) => {
        return dayjs.utc(date);
    },
    getDateSaperatedByHours: (date, hours = 0, minutes = 0, format = 'YYYY-MM-DD HH:mm') => {
        return dayjs(date).add(hours, 'hour').add(minutes, 'minute').format(format);
    },
    getDateAddedByDays: (days, date = new Date(), format = 'YYYY-MM-DD' ) => {
        return dayjs(date).add(days, 'day').format(format);
    },
    formatUtcDate: (date,format = 'DD-MM-YYYY') => {
        return dayjs.utc(date).format(format);;
    },
    addUtcOffset: (utcDate, hours = 0, minutes = 0, format = 'hh:mm A') => {
        return dayjs.utc(utcDate).add(hours, 'hour').add(minutes, 'minute').format(format);
    },
    convertToTimestamp(date) {
        return dayjs(date).unix();
    },
    convertDateToTimstamp(date) {
        return dayjs(date).unix();
    },

} 

export default dateTime