<template>
	<router-view v-if="$route.name == 'PageNotFound' && isSiteStatusCalled"></router-view>
  	<div v-else-if="isSiteStatusCalled">
		<div id="mySidenav" class="sidenav" :class="{ 'nav-left': isSideNav }">
			<div class="close-sidebar-menu"></div>
			<SideMenu @close-side-nav="closeSideNav()" />
		</div>

		<div id="myAcnav" class="sidenav myAcnav" :class="{ 'ac-right': isMobileSideBar }">
			<div class="sidebar-details">
				<MobileSiderBar @close-mobile-sidebar="isMobileSideBar = !isMobileSideBar" />
			</div>
		</div>

		<div id="body-outer" :class="{ 'nav-body': isSideNav, 'ac-body': isMobileSideBar }">
			<div class="overlay-body" @click="isSideNav = false; isMobileSideBar = false"></div>
			<template v-if="!underMaintenance">
				<header v-if="checkForHeaderShow()">
					<Header @open-side-nav="isSideNav = !isSideNav" @open-mobile-sidebar="isMobileSideBar = !isMobileSideBar"></Header>
				</header>
			</template>

			<main class="main-content"
				:class="$route.name == 'sports-event-detail' && eventDetailHideSections && eventDetailHideSections.includes('show_full_screen') ? 'py-0' : ''">
				<router-view v-if="$siteSettings && $siteSettings.data" />
			</main>
			<footer v-if="!['virtual-sport','sports-event-detail']?.includes($route.name)">
				<Footer></Footer>
			</footer>

		</div>

	</div>


	<div v-if="successMsg" class="successfully-wrapper show" style="z-index: 9999999999999999;" ref="successmodal"
		id="successfullyModal">
		<SuccessModal :message="successMsg" />
	</div>

	<div v-if="errorMsg" style="z-index: 9999999999999999;" class="successfully-wrapper show" id="wrongtoaster">
		<ErrorModal :message="errorMsg" />
	</div>


	<Offers v-if="$siteSettings?.data?.offer?.image" />
	<ChangeAndWithdrawPasswordModal :handleUserIdAndOffer="handleUserIdAndOffer"/>
    <UserIdModal/>
	<div id="load" v-if="getGlobaLoader() || !isSiteStatusCalled">
		<div id="load-inner"><i class="fa fa-spinner fa-spin"></i></div>
	</div>
</template>

<script>
import axios from 'axios';
import { AUTH_TOKEN } from './shared/constants/cookies-const';
import { setHeaders } from './shared/services/headers';
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';
import socketService from './socket/sport-socket-service';
import confetti from "canvas-confetti";

export default {
  	name: 'App',
	data() {
		return {
			isSideNav: false,
			isMobileSideBar: false,
			onLine: navigator.onLine,
			showBackOnline: false,
			isSiteStatusCalled: false
		}
	},
  	created(){
		this.getSiteVersion();  
	},
	computed: {
		...mapGetters(['eventDetailHideSections','successMsg', 'errorMsg', 'siteVersion','underMaintenance','logInData']),
		routePath() {
			return this.$route.name
		},
		domainName() {
			return this.$store.getters.domainName;
		}
	},
	mounted() {
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		setHeaders();  
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		this.$errorToast(null);
		this.$successToast(null);
		this.socketAllEvents();
	},

	components: {
		Header: defineAsyncComponent(() =>
			import('./shared/components/header/Header.vue')
		),
		SideMenu: defineAsyncComponent(() =>
			import('./shared/components/header/SideMenu.vue')
		),
		Footer: defineAsyncComponent(() =>
				import('./shared/components/footer/Footer.vue')
		),
		MobileSiderBar: defineAsyncComponent(() =>
				import('./shared/components/header/MobileSiderBar.vue')
		),
		SuccessModal: defineAsyncComponent(() =>
				import('@/shared/components/modal/SuccessModal.vue')
		),
		ErrorModal: defineAsyncComponent(() =>
				import('@/shared/components/modal/ErrorModal.vue')
		),
		Offers: defineAsyncComponent(() =>
			import('./shared/components/header/Offers.vue')
		),
		ChangeAndWithdrawPasswordModal: defineAsyncComponent(() =>
			import('@/shared/components/modal/ChangeAndWithdrawPasswordModal.vue')
		),
		UserIdModal: defineAsyncComponent(() =>
			import('@/shared/components/modal/UserIdModal.vue')
		),
	},
	methods: {
		callAnimation(){
			let duration = 1000;
			let end = Date.now() + duration;
			function frame() {
				confetti({
					particleCount: 7,
					angle: 60,
					spread: 55,
					origin: { x: 0 },
				});
				confetti({
					particleCount: 7,
					angle: 120,
					spread: 55,
					origin: { x: 1 },
				});

				if (Date.now() < end) {
					requestAnimationFrame(frame);
				}
			}

			frame(); 
		},
		handleUserIdAndOffer(){
			if(this.logInData?.logInWithMobile){
				if(!this.logInData?.userIds?.includes(this.$auth?.user?.userid )){
					this.showUserId();
				}
				else {
					this.handleOffer();
				}
			}
			else{
				if(!this.logInData?.userIds?.includes(this.$auth?.user?.userid )){
					let newUserIdsList = this.logInData?.userIds || [];
					newUserIdsList.push(this.$auth?.user?.userid)
					let data = {
						"logInWithMobile": this.logInData?.logInWithMobile,
						"userIds": newUserIdsList
					}
					this.$store.dispatch('setLogInData', data);
				}
				this.handleOffer();
			}
		},
		handleOffer(){
			let is_offer = this.$store.getters.is_offer;
			if (this.$auth.status && this.$siteSettings.data.offer != null && is_offer && this.$siteSettings?.data?.business_type == 2) {
				this.$helper.openModal("#offer_pop_up");
			}
		},
		showUserId() {
			this.callAnimation();
            this.$helper.openModal('#userid_Modal');
        },
		getGlobaLoader() {
				return this.$store.getters.globalLoader;
			},
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.events;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i])
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},
		getSiteVersion() {
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.error) {
						this.$errorToast(response.data.error);
					} else {
						let resData = response.data.data;
						let currentVersion = resData?.version;
						let userSystemVersion = this.siteVersion || 0;
						
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.$siteSettings.data) {
							this.$store.dispatch('setSiteVersion', currentVersion);
							this.isSiteStatusCalled = false;
							this.getSiteSettingsServiceCall();
						} else {              
							this.setSiteSttings(this.$siteSettings.data);
						}
						if (resData?.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();
							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;
							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$router.push({ name: 'Maintenance' });
							}
						}
						setTimeout(() => {
							this.getSiteVersion();
						}, 30000);
						this.$store.dispatch('setUnderMaintenance', resData.maintenance);
					}
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		getSiteSettingsServiceCall() {
			this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data.data;
						resData.siteSetting.announcement = JSON.parse(resData.siteSetting.announcement)	
						resData.siteSetting.offer = resData.siteSetting.poster.find( pos => pos.poster_type == 12);
						let casino;
						if(resData.casino_url) {
							const getCasinoGame = async () => {
							let gameResp = await axios.get(resData.casino_url)
							return gameResp.data
							};
							casino = await getCasinoGame();
						} else {
							casino = resData?.casinoGames
						}	
						this.$store.dispatch('setSettings', resData.siteSetting);
						this.$store.dispatch('setGamesData', casino);
						this.$store.dispatch('setBonusData', resData.bonus_data);
						this.setSiteSttings(resData.siteSetting);

					}
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		
		setSiteSttings(siteData) {	
			if(this.isSiteStatusCalled) return;

			if (siteData.languagesList) {
				this.$setCurrentLangData(siteData.languagesList, siteData.language_code, true, siteData.aws_url)
			}
			if(siteData.meta_tags) {
				document.head.innerHTML += siteData.meta_tags;
			};
			if(siteData.g_tags) {
				this.setGoogleAnalyticsTag(siteData.g_tags);
			};
			if(siteData.site_map) {
				const sitemapLink = document.createElement("link");
				sitemapLink.rel = "sitemap";
				sitemapLink.type = "application/xml";
				sitemapLink.title = "Sitemap";
				sitemapLink.href = siteData.aws_url + siteData.storage_path.site_map + siteData.site_map;
				document.head.appendChild(sitemapLink);
			};
			
			this.$store.dispatch('setSettings', siteData);
			this.setManifest(siteData);
			// Create FAVICON
			const favElement = document.createElement('link');
			favElement.rel = 'icon';
			favElement.href = siteData.aws_url + siteData.storage_path.domain_image + siteData.fav_logo;
			document.head.insertAdjacentElement('beforeend', favElement);

			const title = document.getElementById("title");
			title.text = siteData.domain_name;
			if (this.$auth.status) {
				this.getWalletBalance();
				this.getFavourites();
			}
			try {
				if (siteData.front_css != null && siteData.front_css != '') {
					const styleElement = document.createElement('style');
					styleElement.type = 'text/css';

					styleElement.appendChild(document.createTextNode(siteData.front_css));
					document.head.insertAdjacentElement('beforeend', styleElement);
				}
				} catch (error) {
			}
			this.isSiteStatusCalled = true;
			if(this.$auth?.status) {
				if(!this.$auth?.user?.last_login_at){
					this.$helper.openModal("#ChangeAndWithdrawPassword");
					return;
				} else{
					this.handleUserIdAndOffer();
				}
			}
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		setGoogleAnalyticsTag(gTag) {
			let script1 = document.createElement('script');
			script1.async = true;
			script1.src = `https://www.googletagmanager.com/gtag/js?id=${gTag}`;

			let script2 = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${gTag}');
			`;
			
			document.head.appendChild(script1);
			document.head.appendChild(script2);
		},
		getWalletBalance() {
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.expl = response.data.ex;
					user.bets_count = response.data.bc;
					user.earnBonus = response.data.eb;
					this.$store.dispatch('setUser', user);
					if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
						this.refreshAuthToken();
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 4000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if (refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
				this.refreshInit = false;
			});
		},
		closeSideNav() {
			if (this.isSideNav)
				this.isSideNav = false;
		},
		updateOnlineStatus(e) {
			const { type } = e;
			this.onLine = type === 'online';
		},
		checkForHeaderShow() {
			if (this.routePath == 'search') {
				return false;
			} else if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		socketAllEvents() {
			if (this.$auth.status) {
				socketService.emit("sub", 'DepositRequest-' + this.$auth.user.userid);

				socketService.on("DepositRequest", (data) => {
					this.$successToast(data.msg)
				});
			}
		},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		},
	}
}
</script>
