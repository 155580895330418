const NotificationModule = () => import(/* webpackChunkName: "notification-module" */ './views/Module.vue');
const Notification = () => import(/* webpackChunkName: "notification" */ './views/Notification.vue');

const NotificationRoutes = {
    path: '/',
    component: NotificationModule,
    children: [
        {
            path: 'notifications',
            name: 'Notification',
            component: Notification
        }
    ],
}

export default NotificationRoutes;