const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const WinLoss = () => import(/* webpackChunkName: "win-loss" */ './views/WinLoss.vue');
const BetHistory = () => import(/* webpackChunkName: "open-bets" */ './views/BetHistory.vue');
const WinLossDetail = () => import(/* webpackChunkName: "profit-loss" */ './views/WinLossDetail.vue');
const Transactions = () => import(/* webpackChunkName: "profit-loss" */ './views/Transactions.vue');
const TransactionDetails = () => import(/* webpackChunkName: "profit-loss" */ './views/TransactionDetails.vue');

const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'bets',
            name: 'bets',
            component: Bets,
        },
        {
            path: 'win-loss',
            name: 'win-loss',
            component: WinLoss,
        },
        {
            path: 'bet-history',
            name: 'bet-history',
            component: BetHistory,
        },
        {
            path: 'win-loss-detail',
            name: 'WinLossDetail',
            component: WinLossDetail
        },
        {
            path: 'transactions',
            name: 'Transactions',
            component: Transactions
        },
        {
            path: 'transaction-details/:id',
            name: 'TransactionDetails',
            component: TransactionDetails
        },
    ],
}

export default BetsRoutes;