export const routes = {
    user: {
        GET_SITE_SETTINGS: 'api/site-settings',
        WALLET_BALANCE: 'api/user-info',
        AUTH_REFRESH: 'api/auth-refresh',
        LOGIN_API:'api/user-login',
        DEMO_LOGIN_API:'api/demo-user',
        LOGOUT_API:'api/logout',
        GET_FAVOURITE_LIST:'api/favorite-list',
        GET_EVENT_EXPOSURES: 'api/get-event-book-and-fancy-exposures-by-event-id/:matchId',
        OPEN_BETS_EVENT_LISTING: 'api/get-open-bets',
        GET_BET_HISTORY: 'api/get-my-bets',
        SAVE_BETS: 'api/save-bet',
        BET_CASHOUT: 'api/cashout',
        GET_FANCY_BOOK: 'api/get-fancy-book',
        MAKE_FAVOURITE: 'api/make-event-favorite',
        CHANGE_STAKE_VALUES: 'api/change-stake-values',
        GET_WALLET_GETWAY: 'api/get-payment-getway-iframe-url',
        GET_CASINO_GAMES: 'api/get-casino-games',
        GET_MY_MARKETS: 'api/get-my-markets/:sport_type',
        GET_PREMIUM_FANCY_URL: 'api/load-third-party-url',
        GET_SITE_RULES: 'api/get-site-rules',
        UPDATE_WALLET_TYPE: 'api/wallet-switch',
        SEND_OTP: '/api/send-otp',
        LOGIN_WITH_OTP : 'api/login-with-otp',
        REGISTER: 'api/registration',
        FORGOT_PASSWORD: 'api/forgot-password',
        RESET_FORGOT_PASSWORD: 'api/reset-forgot-password',
        CHANGE_MY_PASSWORD: 'api/change-my-password',
        GET_SITE_VERSION: 'api/site-status-version',
        GET_MATKA_SINGLE_BETS: 'api/get-matka-single-bets/:id',
        GET_STATEMENTS: 'api/get-statements',
        GET_PROFIT_LOSS: 'api/get-user-pl-info',
        GET_PROFIT_LOSS_DETAIL: 'api/get-profit-loss-detail',
        GET_MARKET_BETS: 'api/get-market-bets',
        GET_PROFIT_LOSS_MATCH_DETAIL: 'api/get-user-match-statements',
        GET_PROFIT_LOSS_PL_DETAIL: 'api/get-user-pl-statements',
        GET_LIVE_POCKER_URL: 'api/get-live-poker-url/:game_code',
        DREAM_CASINO: 'api/dreamcasino/load-game/:game_code/:sm_id',
        UNIVERSE_CASINO: 'api/universe/',
        GET_CHATBOT_IFRAME : 'api/chatbot/get-iframe-url',
        GET_CHATBOT_IFRAME_GUEST  : 'api/chatbot/get-iframe-url-for-guest',
        GET_ALL_MARKET_BOOK:'api/get-all-event-book-and-fancy-exposures-by-event-id',
        GET_REFER_CODE:'api/get-refer-code',
        GET_SPORTS_BOOK_URL:  'api/get-sap-sportsbook-url',
        SEARCH:'api/searching-event/:searchKey',
        GET_PARENT_PAYMENT_METHODS:'api/parent-payment-methods',
        ADD_USER_ACCOUNT:'api/accounts/store-account',
        SUBMIT_WITHDRAW_AMOUNT: 'api/wallet-withdraw',
        REMOVE_USER_ACCOUNT: 'api/accounts/:id',
        SEND_VERIFICATION_CODE: 'api/send-verification-code',
        GET_USER_ACCOUNTS: 'api/accounts',
        BANK_DETAILS:'api/bank-details',
        PREVIOUS_BANK_DETAILS:'api/accounts?acc=pre',
        GET_PARENT_PAYMENT_METHOD_DETAILS:'api/parent-payment-methods-details/:id/:bankId/:amount',
        GET_WALLET_LIMIT:'api/wallet/wallet-limits',
        GET_CHAT_LIST:'api/fetch-user-messages/:id',
        SEND_CHAT_MESSAGE:'api/send-message',
        GET_THIRD_PARTY_API: 'api/load-third-party-url',
        GET_AUTO_UTR: 'api/wallet/get-auto-utr',
        GET_BONUS_STATEMENT: 'api/user-bonus-statement/:bonusType',
        REDEEM_USER_BONUS: 'api/redeem-user-bonus',
        CHANGE_USER_BONUS: 'api/change-user-bonus',
        SUBMIT_DEPOSIT_AMOUNT: 'api/wallet-store',
        MARK_ALL_NOTIFICATION_READ: 'api/mark-all-as-read',
        SAVE_WORLI_MATKA_BET:'api/save-worli-matka-bet',
        UPDATE_WITHDRAW_PSSWORD: 'api/accounts/update-withdraw-password',
        STORE_WITHDRAW_PSSWORD: 'api/accounts/store-withdraw-password',
        GET_WALLET_HISTORY: 'api/wallet/wallet-history',
        GET_TRANSACTION_DETAILS: 'api/transaction-show/:id',
        GET_REFERED_USER_LOG: 'api/get-bonus-log',
        UPDATE_USER_NAME: 'api/update-display-name',
        
        //BONUS
        GET_REFERED_USER: 'api/get-referred-users',

        //POOL ZONE
        JOIN_POOL:'api/join-pool',

        //AFFILIATE
        GET_AFFILIATE_DASHBOARD: 'api/get-affiliate-dashboard',
        GET_AFFILIATE_USERSLIST: 'api/get-affiliate-user-lists',
        GET_PROFIT_LOSS_EVENT_USERS: 'api/get-affiliate-profit-loss-events',
        AFFILIATE_REGISTER: 'api/add-user',
        GET_AFFILIATE_REGISTER_USER_COUNT: 'api/get-affiliate-registered-users-count',
    },
    pool: {
        GET_POOLS:'api/get-pools',
        POOL_LEADER_BOARD:'api/pool-leader-board',
        POOL_WALLET:'api/user-info',
        GET_FANCY_BOOK: 'api/get-fancy-book',
        GET_EVENT_EXPOSURES: 'api/get-event-book-and-fancy-exposures-by-event-id/:matchId',
        GET_POOL_OPEN_BETS: 'api/pool-bets',
        GET_POOL_WINNERS: 'api/pool-winners'
    },
    bonus: {
        GET_BONUS_LOGS: 'api/v1/get-bonus-log',
        GET_BONUS_LOGS_DETAILS: 'api/v1/get-bonus-log-details',
        GET_USER_LOCKED_BONUS: 'api/v1/get-user-locked-bonus',
        GET_LOCKED_EARNED_BONUS: 'api/v1/get-locked-earn-bonus',
    },
    notification: {
        NOTIFICATIONS_LIST_API: 'api/get-notification',
        UNREAD_NOTIFICATION_COUNT: 'api/get-unread-notifications-count',
        SUBSCRIBE_NOTIFICATION: 'api/subscribe-notification'
    },
    sports: {
        MATCHES_BY_SPORT_ID: 'v1/events/matches/:sport_id',
        GET_SPORTS: 'v1/sports/management/getSport',
        GET_MATCH_DETAILS: 'v1/events/matchDetails/:event_id',
        FIND_BY_IDS: 'v1/events/findByIds',
        INPLAY_MATCHES_COUNT: "v1/events/count",
        GET_MATKAS: '/v1/worli/public/matches',
        GET_MATKA_MARKETS: '/v1/worli/public/matches/:id/all/markets',
        INPLAY_MATCHES_LIST :'v1/events/matches/inplay',
        GET_UPCOMING_SPORTS:'v1/events/sports/all',
        //POOL ZONE
        INPLAY_POOL_LIST: 'v1/events/matches/inplay/pool',
    },
    fantasy: {
        FANTASY_SETUP_DATA: '/get-setup-data',
        GET_REMEMBERED_MATCHES: '/get-remembered-matches',
        ADD_MATCH_REMINDER: '/add-match-reminder',
        REMOVE_MATCH_REMINDER: '/remove-match-reminder',
        PLAYERS_LIST_API: '/get-match-players-list/:matchId',
        GET_MY_STAKES: '/get-my-stakes',
        UPDATE_MY_STAKES: '/update-my-stakes',
        ADD_MY_TEAM: '/add-my-team',
        UPDATE_MYTEAM_NAME: '/update-my-teamname',
        BET_DETAILS: '/get-match-bet-details',
        PLACE_BET: '/place-bet',
        MY_CONTESTS_LIST: '/get-my-contests',
        MY_CONTEST_BET_LIST: '/get-match-teams-and-bet-details',
        CANCEL_BET: '/cancel-bet',
        MATCHES_LIST_API: '/get-matches-list',
        TUTORIALS_API: '/get-tutorials',
        GET_TEAM_DETAILS: '/get-team-details/:id',
        RESET_MYTEAM: '/reset-my-team',
    },
};