<template>
    <div class="modal fade game-point-note-modal" id="gamePop2" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content">
                            <img class="gp-img" src="@/assets/images/slotsCasinoPopUp.webp" alt="slotsCasinoPopUp">
                            <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/closeButton.webp" alt="closeButton">
                            <div class="gp-title">PLEASE NOTE</div>
                            <div class="gp-descr">(1 Point = ₹ 10)</div>
                            <div class="gp-welcome">Welcome to your new casino lobby</div>
                            <div class="gp-checkout">Checkout the exciting Live Casino tables, Slots and much more, upto
                                200+ game and counting...</div>
                            <div @click="$emit('agreed')" class="gp-button thm-but ok-agree-btn">OK, I AGREE !</div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <!-- <div ref="iframe_pop_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal">
    </div>

    <div class="modal fade game-play-modal" data-bs-backdrop="static" data-bs-keyboard="false" id="iframe_pop_modal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div  class="modal-dialog modal-fullscreen"  style="max-width: 100%;">
            <IFrameModal @close-modal="openIframeModal=false" v-if="openIframeModal" />
        </div>

    </div> -->
</template>
<script>

export default {
    name: 'GamesPop2',
}
</script>