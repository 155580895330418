import { io } from "socket.io-client";
const socket = io(process.env.VUE_APP_CLICK_API_SOCKET, {transports: ['websocket'], upgrade: false});

class SocketService {
    constructor() {
        this.socketIo = socket;
        this.client = null;
        this.currSub = [];

        this.socketIo.on("connect", () => {
            this.client = this.socketIo.id;
            this.currSub.forEach((sub) => {
                this.socketIo.emit(sub.event, sub.data);
            });
        });
    }

    on(event, listener) {
        this.socketIo.on(event, listener);
    }

    off(event) {
        this.socketIo.off(event);
    }

    emit(event, data) {
        if (event == "leave") {
            data.forEach(item => {
                this.currSub = this.currSub.filter(sub => sub.data != item);
            })
        } else {
            const exists = this.currSub.some((sub) => sub.event === event && sub.data === data);
            if (!exists) {
                this.currSub.push({ event, data });
            }
        }
        if (this.socketIo.connected) {
            this.socketIo.emit(event, data);
        }
    }
}

export default new SocketService();