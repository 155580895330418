const AffiiateModule = () => import(/* webpackChunkName: "affiliate-module" */ './views/Module.vue');
const AffDashboard = () => import(/* webpackChunkName: "dashboard" */ './views/DashBoard.vue');
const AffUsersList = () => import(/* webpackChunkName: "users-list" */ './views/UsersList.vue');
const AffProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const AffStatement = () => import(/* webpackChunkName: "statement" */ './views/Statement.vue');
const AffReports = () => import(/* webpackChunkName: "reports" */ './views/Reports.vue');
const UserProfitLoss = () => import(/* webpackChunkName: "user-profit-loss" */ './views/UserProfitLoss.vue');

const AffiliateRoutes = {
    path: '/affiliate',
    component: AffiiateModule,
    children: [
        {
            path: 'dashboard',
            name: 'AffDashboard',
            component: AffDashboard
        },
        {
            path: 'users-list',
            name: 'AffUsersList',
            component: AffUsersList
        },
        {
            path: 'profit-loss',
            name: 'AffProfitLoss',
            component: AffProfitLoss
        },
        {
            path: 'statement/:affiliateId',
            name: 'AffStatement',
            component: AffStatement
        },
        {
            path: 'reports',
            name: 'AffReports',
            component: AffReports
        },
        {
            path: 'user-profit-loss/:affiliateId',
            name: 'UserProfitLoss',
            component: UserProfitLoss
        }
    ],
}

export default AffiliateRoutes;