import { h, render } from 'vue';
import { Modal } from 'bootstrap';
import GamesIframe from '@/shared/components/games/GamesIframe.vue';
import GamePop1 from '@/shared/components/games/GamePop1.vue';
import GamePop2 from '@/shared/components/games/GamePop2.vue';
import { apiCall } from '@/shared/services/apiCall';
import { errorToast } from './helper';

const loadCasinoGame = {
    gameData: {
        gameUrl: null,
        gameName: null
    },
    resolvePromise: null,
    confirmModal: null,
    gameItem: null,
    userData: null,

    loadGame(game, user) {
        this.gameItem = game;
        this.userData = user;

        switch (user.currency) {
            case 1:
                if (game.only_hkd == 1) {
                    this.getPointsConfiramtion(1);
                }
                else if (game.only_hkd == 0) {
                    this.callForGamesApi();
                }
                break;
            case 2:
                if (game.only_hkd == 1) {
                    this.getPointsConfiramtion(1);
                } else if (game.only_hkd == 0) {
                    this.getPointsConfiramtion(2);
                }
                break;
            case 3:
            case 4:
                this.callForGamesApi();
                break;
        };

    },
    getDreamCasinoLoadGames() {
        window.store.dispatch('setGlobalLoader', true);
        let data = {
            type: 'casino',
            game_code: this.gameItem.game_code,
            section_id: this.gameItem.sm_id
        };
        apiCall('post', 'GET_THIRD_PARTY_API', data, 'user').then(response => {
            if (response) {
                if (response.status == 200) {
                    window.store.dispatch('setGlobalLoader', false);
                    if(response.data.error) {
                        errorToast(response.data.error);
                    } else {
                        let iFrameUrl = response?.data?.url;
                        if (iFrameUrl == null || iFrameUrl == undefined || iFrameUrl == '') {
                            errorToast('URL not found.')
                            return;
                        };
                        this.gameData = {
                            gameUrl: iFrameUrl,
                            gameName: this.gameItem.game_name
                        };
                        this.loadIframe();
                    }
                }
            }
        }).catch(error => {
            window.store.dispatch('setGlobalLoader', false);
            if (error) {
                errorToast(error[0]);
            }
        });
    },
    async getPointsConfiramtion(popUpType) {

        let confirmationPromise = new Promise((resolve) => {
            this.resolvePromise = resolve;
            this.pointsConfirmationInit(popUpType);
            this.confirmModal.show();
        });

        confirmationPromise.then(res => {
            if (res.isConfirmed) {
                this.confirmModal.hide();
                this.callForGamesApi();
            }
        });
    },
    callForGamesApi() {
        if(window.store.getters.stateUser?.actw == 'WB') {
            errorToast('Casino not allowed in welcome balance.')
            return
        };
        this.getDreamCasinoLoadGames()
    },
    createModal(modalComponent, props, modalId) {
        if (!document.getElementById(`games-modal-${modalId}`)) {

            const appInstance = document.getElementById('app');
            const modalContainer = document.createElement('div');
            modalContainer.id = `games-modal-${modalId}`
            appInstance.appendChild(modalContainer);

            let modalInstance = h(modalComponent, props)
            render(modalInstance, modalContainer);

        };

        this.confirmModal = new Modal(document.getElementById(modalId));
    },
    pointsConfirmationInit(popType) {
        const component = popType === 1 ? GamePop1 : GamePop2;
        const modalId = popType === 1 ? 'gamePop1' : 'gamePop2';
        this.createModal(component, { onAgreed: this.handleAgreed.bind(this) }, modalId);
    },
    handleAgreed() {
        if (this.resolvePromise) {
            this.resolvePromise({ isConfirmed: true });
        }
        this.confirmModal.hide();
    },
    loadIframe() {
        this.createModal(GamesIframe,
            {
                gameData: this.gameData,
                onCloseGame: this.closeGame.bind(this)
            },
            'gamesIframe'
        );
        this.confirmModal.show();
    },
    closeGame() {

        this.confirmModal.hide();

        const modalContainer = document.getElementById('games-modal-gamesIframe');
        if (modalContainer) {
            modalContainer.remove();
        }
        this.confirmModal = null;
    }

};

export default loadCasinoGame;